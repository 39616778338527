import React, { useEffect, useState } from "react";
import "../styles/Result.scss";
import { functions } from "./Firebase";
import { httpsCallable } from "firebase/functions";
import "../styles/shared.scss";
import _ from "lodash";
import gptTokenizer from "gpt-tokenizer";
import Markdown from "react-markdown";

const OPTIONS = {
  temperature: {
    min: 0,
    max: 2,
    name: "Temperature",
  },
  max_tokens: {
    min: 1,
    max: 4096,
    name: "Maximum length",
  },
  top_p: {
    min: 0,
    max: 1,
    name: "Top P",
  },
  frequency_penalty: {
    min: 0,
    max: 2,
    name: "Frequency penalty",
  },
  presence_penalty: {
    min: 0,
    max: 2,
    name: "Presence penalty",
  },
};

async function getOpenAiResponse(payload) {
  const getOpenAiResponseFunc = httpsCallable(functions, "getOpenAiResponse");
  const stringPayload = JSON.stringify(payload);

  const result = await getOpenAiResponseFunc(stringPayload);
  return result.data.result;
}

function numTokensFromString(message) {
  if (!message) {
    return 0;
  }
  const encoded = gptTokenizer.encode(message);
  return encoded.length;
}

const Control = ({ params, setParams, option }) => {
  const [tempValue, setTempValue] = useState(params.data[option]);

  useEffect(() => {
    setTempValue(params.data[option]);
  }, [params, option]);

  const handleBlur = () => {
    let value = Number(tempValue);
    if (isNaN(value)) {
      value = params.data[option];
    } else if (value < OPTIONS[option].min) {
      value = OPTIONS[option].min;
    } else if (value > OPTIONS[option].max) {
      value = OPTIONS[option].max;
    }
    setParams((prevParams) => ({
      ...prevParams,
      data: {
        ...prevParams.data,
        [option]: value,
      },
    }));
    setTempValue(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleBlur();
    }
  };

  return (
    <div className="ResultControl">
      <div className="inputContainer">
        <span>{OPTIONS[option].name}</span>
        <input
          type="text"
          min={OPTIONS[option].min}
          max={OPTIONS[option].max}
          value={tempValue}
          onChange={(event) => setTempValue(event.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
      </div>
      <input
        type="range"
        min={OPTIONS[option].min}
        max={OPTIONS[option].max}
        value={params.data[option]}
        onChange={(event) => {
          setParams((prevParams) => ({
            ...prevParams,
            data: {
              ...prevParams.data,
              [option]: Number(event.target.value),
            },
          }));
          setTempValue(event.target.value);
        }}
      />
    </div>
  );
};

function Result({ params, setParams }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showMarkdown, setShowMarkdown] = useState(params.showMarkdown);

  const handleCheckboxChange = (event) => {
    setShowMarkdown(event.target.checked);
    setParams(() => ({
      ...params,
      showMarkdown: !showMarkdown,
    }));
  };

  const hasDataChanged = !_.isEqual(params.history[params.index], params.data);
  const tokensRequested = params.data
    ? numTokensFromString(params.data.userPreview + params.data.systemPreview) +
      params.data.max_tokens
    : 0;
  const tokensMax = 4096;

  const handleClick = async () => {
    const payload = {
      model: params.data.model,
      messages: [
        {
          role: "system",
          // content: params.data.systemPreview,
          content: params.data.systemPreview,
        },
        {
          role: "user",
          // content: params.data.userPreview,
          content: params.data.userPreview,
        },
      ],
      temperature: params.data.temperature,
      max_tokens: params.data.max_tokens,
      top_p: params.data.top_p,
      frequency_penalty: params.data.frequency_penalty,
      presence_penalty: params.data.presence_penalty,
      stop: params.data.stop,
    };
    setIsLoading(true);
    const result = await getOpenAiResponse(payload);

    const newData = {
      ...params.data,
      result,
    };

    setParams(() => ({
      index: params.history.length,
      history: [...params.history, newData],
      data: newData,
    }));
    setIsLoading(false);
  };

  const indexOptions = [];
  for (let i = 0; i < params.index; i++) {
    indexOptions.push(
      <option key={params.index} value={params.index}>{`Run ${
        params.index + 1
      }`}</option>
    );
  }

  const resultText = isLoading
    ? "Waiting for OpenAI response..."
    : params.index === params.history.length || hasDataChanged
    ? ""
    : params.data.result;

  return (
    <div className="Result">
      <div className="ResultContent">
        <div className="ResultBox">
          <div className="ResultHeader">
            <div className="ResultHeaderLeft">
              <span>Output</span>
              <button
                className="blueButton"
                onClick={handleClick}
                disabled={isLoading || tokensRequested > tokensMax}
              >
                Submit
              </button>

              <label className="MarkdownCheckbox">
                <input
                  type="checkbox"
                  checked={showMarkdown}
                  onChange={handleCheckboxChange}
                />
                Markdown
              </label>
              {tokensRequested > tokensMax ? (
                <span className="ResultError">{`Error: context length exceeded (${tokensRequested} > ${tokensMax})`}</span>
              ) : (
                <></>
              )}
            </div>
            <div className="ResultHeaderRight">
              <span>History</span>
              <select
                className="historyDropdown"
                value={hasDataChanged ? params.history.length : params.index}
                onChange={(event) => {
                  const newIndex = Number(event.target.value);
                  setParams({
                    index: newIndex,
                    history: params.history,
                    data:
                      newIndex < params.history.length
                        ? params.history[newIndex]
                        : {
                            ...params.data,
                          },
                  });
                }}
              >
                {Array.from(
                  { length: params.history.length + 1 },
                  (_, i) => i + 1
                ).map((number) => (
                  <option key={number} value={number - 1}>
                    {number <= params.history.length
                      ? `Run ${number}`
                      : `New Run`}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {showMarkdown ? (
            <div className="ResultText ResultMarkdown">
              <Markdown>{resultText}</Markdown>
            </div>
          ) : (
            <pre className="ResultText ResultPre">{resultText}</pre>
          )}
        </div>
        <div className="ResultControls">
          <span>Controls</span>
          <div className="ResultControlsContents">
            <div className="ResultControl">
              <div>Model</div>
              <div>
                <select
                  name="model"
                  id="model"
                  value={params.data.model}
                  onChange={(event) =>
                    setParams((prevParams) => ({
                      ...prevParams,
                      data: {
                        ...prevParams.data,
                        model: event.target.value,
                      },
                    }))
                  }
                >
                  <option value="gpt-3.5-turbo">gpt-3.5-turbo</option>
                  <option value="gpt-3.5-turbo-16k" disabled>
                    gpt-3.5-turbo-16k 🔒
                  </option>
                  <option value="gpt-4" disabled>
                    gpt-4 🔒
                  </option>
                  {/* <option value="gpt-4-1106-preview"> */}
                  <option value="gpt-4-1106-preview" disabled>
                    gpt-4 turbo 🔒
                  </option>
                </select>
              </div>
            </div>
            <Control
              params={params}
              setParams={setParams}
              option={"temperature"}
            />
            <Control
              params={params}
              setParams={setParams}
              option={"max_tokens"}
            />

            <div className="ResultControl">
              <div>Stop sequences</div>
              <input
                type="text"
                value={params.stop}
                onChange={(event) =>
                  setParams((prevParams) => ({
                    ...prevParams,
                    data: {
                      ...prevParams.data,
                      stop: event.target.value,
                    },
                  }))
                }
              />
            </div>
            <Control params={params} setParams={setParams} option={"top_p"} />
            <Control
              params={params}
              setParams={setParams}
              option={"frequency_penalty"}
            />
            <Control
              params={params}
              setParams={setParams}
              option={"presence_penalty"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Result;
