import React from "react";
import "../styles/Header.scss";
import { getAuth, signOut } from "firebase/auth";
import Share from "./Share";
import { useNavigate } from "react-router-dom";

const auth = getAuth();

const logout = () =>
  signOut(auth)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    });

function Header(props) {
  const navigate = useNavigate();
  return (
    <div className="Header">
      {props.children ? (
        props.children
      ) : (
        <div>
          <div className="HeaderLeft">
            <div className="HeaderText">
              <a href="https://productprompt.ai">ProductPrompt</a>

              {/* ProductPrompt */}
            </div>
            <Share params={props.params} />
          </div>
          <div className="HeaderRight">
            <a href="mailto:hi@productprompt.ai">
              <p className="upgrade">Upgrade</p>
            </a>
            {props.loggedIn ? (
              <p className="signOut" onClick={logout}>
                Sign Out
              </p>
            ) : (
              <p className="signOut" onClick={() => navigate("/login")}>
                Sign In
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
