import React, { useState, useEffect } from "react";
import { db } from "./Firebase";
import "../styles/shared.scss";
import { doc, setDoc } from "firebase/firestore";

function removeEmptyKeys(json) {
  for (let key in json) {
    if (!key) {
      delete json[key];
    } else if (typeof json[key] === "object") {
      removeEmptyKeys(json[key]);
    }
  }
  return json;
}

function Share({ params }) {
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    setIsSuccess(false);
  }, [params]);

  const saveParamsToFirestore = async () => {
    setLoading(true);
    try {
      const HACK_ID = ""; // DON'T COMMIT VALUE

      const randomId = [...Array(10)]
        .map(() => Math.random().toString(36)[2])
        .join("");
      const docRef = doc(db, "paramsCollection", HACK_ID || randomId);
      console.log(params);
      removeEmptyKeys(params);
      await setDoc(docRef, {
        ...params,
        canBeViewedLoggedOut: !!HACK_ID,
      });
      // await setDoc(docRef, params);
      setIsSuccess(true);
      window.history.replaceState(null, "Shared", `/${docRef.id}`);
    } catch (error) {
      console.error("Error saving params: ", error);
      alert("Failed to save params!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="Share">
      <button
        className="primaryButton"
        onClick={saveParamsToFirestore}
        disabled={loading || isSuccess}
      >
        {loading ? "Saving..." : isSuccess ? "Saved to URL" : "Share Page"}
      </button>
    </div>
  );
}

export default Share;
