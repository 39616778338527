import React, { useState, useEffect } from "react";
import Handlebars from "handlebars";
import "../styles/Editor.scss";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-handlebars";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

function Editor({ params, setParams }) {
  const [systemError, setSystemError] = useState(false);
  const [userError, setUserError] = useState(false);

  useEffect(() => {
    try {
      const systemPreview = Handlebars.compile(params.data.systemTemplate)(
        params.data.json
      );
      setParams((prevParams) => ({
        ...prevParams,
        data: {
          ...prevParams.data,
          systemPreview,
        },
      }));

      setSystemError(false);
    } catch (e) {
      setSystemError(true);
    }
    try {
      const userPreview = Handlebars.compile(params.data.userTemplate)(
        params.data.json
      );

      setParams((prevParams) => ({
        ...prevParams,
        data: {
          ...prevParams.data,
          userPreview,
        },
      }));
      setUserError(false);
    } catch (e) {
      setUserError(true);
    }
  }, [
    setParams,
    params.data.systemTemplate,
    params.data.userTemplate,
    params.data.json,
  ]);

  return (
    <div className="Editor">
      <div className="EditorMain">
        <div className="EditorInputs">
          <div className="EditorContent">
            <div className="EditorTextarea">
              <span className="EditorTextareaHeader">System</span>
              <div
                className={`EditorAce ${systemError ? "EditorAce-error" : ""} ${
                  params.index < params.data.length ? "EditorAce-disabled" : ""
                } `}
              >
                <AceEditor
                  className="AceEditor"
                  placeholder="Enter your system prompt"
                  mode="handlebars"
                  theme="textmate"
                  name="blah2"
                  onChange={(systemTemplate) =>
                    setParams((prevParams) => ({
                      ...prevParams,
                      data: {
                        ...prevParams.data,
                        systemTemplate,
                      },
                    }))
                  }
                  fontSize={14}
                  scrollMargin={[12, 12, 0, 0]}
                  showPrintMargin={false}
                  showGutter={false}
                  highlightActiveLine={true}
                  value={params.data.systemTemplate}
                  wrapEnabled={true}
                  width={"614px"}
                  setOptions={{
                    indentedSoftWrap: false,
                  }}
                />
              </div>
            </div>
            <div className="EditorTextarea">
              <span className="EditorTextareaHeader">User</span>
              <div
                className={`EditorAce ${userError ? "EditorAce-error" : ""}`}
              >
                <AceEditor
                  className="AceEditor"
                  placeholder="Enter your user prompt"
                  mode="handlebars"
                  theme="textmate"
                  name="blah2"
                  onChange={(userTemplate) =>
                    setParams((prevParams) => ({
                      ...prevParams,
                      data: {
                        ...prevParams.data,
                        userTemplate,
                      },
                    }))
                  }
                  fontSize={14}
                  showPrintMargin={false}
                  showGutter={false}
                  highlightActiveLine={true}
                  value={params.data.userTemplate}
                  wrapEnabled={true}
                  width={"614px"}
                  scrollMargin={[12, 12, 0, 0]}
                  setOptions={{
                    indentedSoftWrap: false,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="EditorContent">
            <div className="EditorTextarea">
              <pre className="EditorPreview">{params.data.systemPreview}</pre>
            </div>
            <div className="EditorTextarea">
              <pre className="EditorPreview">{params.data.userPreview}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Editor;
