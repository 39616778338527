import { JsonEditor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import React, { useEffect, useRef } from "react";
import "../styles/shared.scss";
import "../styles/Import.scss";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/xcode";

export const ControlledJsonEditor = ({ value, onChange }) => {
  const jsonEditorRef = useRef();
  useEffect(() => {
    const editor =
      jsonEditorRef &&
      jsonEditorRef.current &&
      jsonEditorRef.current.jsonEditor;
    if (editor && value) {
      editor.update(value);
    }
  }, [jsonEditorRef, value]);

  useEffect(() => {
    const editor =
      jsonEditorRef &&
      jsonEditorRef.current &&
      jsonEditorRef.current.jsonEditor;
    if (editor) {
      editor.expandAll();
    }
  }, []);

  return (
    <JsonEditor
      htmlElementProps={{ style: { height: 500 } }}
      ref={jsonEditorRef}
      value={value}
      allowedModes={["tree", "code", "text"]}
      theme="ace/theme/xcode"
      ace={ace}
      onChange={onChange}
    />
  );
};

function Import({ params, setParams }) {
  const handleFiles = (e) => {
    if (e.target.files.length === 0) {
      return;
    }

    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const jsonContent = JSON.parse(event.target.result);
        handleEdit(jsonContent);
      } catch (err) {
        console.error("Invalid JSON file.", err);
      }
    };
    reader.readAsText(file);
    e.target.value = "";
  };

  const handleEdit = (json) => {
    setParams((prevParams) => ({
      ...prevParams,
      data: {
        ...prevParams.data,
        json,
      },
    }));
  };

  return (
    <div className="ImportWrap">
      <div className="Import">
        <div className="ImportHeader">
          <div className="ImportHeaderLeft">
            <span>Your Data</span>
          </div>
          <div className="ImportHeaderRight">
            <input
              type="file"
              id="fileUpload"
              accept=".json"
              onChange={handleFiles}
              style={{ display: "none" }}
            />
            <label
              className="blueButton"
              htmlFor="fileUpload"
              style={{ cursor: "pointer" }}
            >
              Upload JSON
            </label>
          </div>
          {/* <input type="file" accept=".json" onChange={handleFiles} /> */}
        </div>
        <ControlledJsonEditor value={params.data.json} onChange={handleEdit} />
        {/* <ControlledJsonEditor value={params} onChange={handleEdit} /> */}
      </div>
    </div>
  );
}

export default Import;
