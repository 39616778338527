import React, { useState, useEffect } from "react";
import { auth } from "./Firebase";
import Login from "./Login";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./Firebase";
import Header from "./Header";
import Import from "./Import";
import Editor from "./Editor";
import Result from "./Result";

function App() {
  const [loginStatus, setLoginStatus] = useState("loading");
  const [loadingParams, setLoadingParams] = useState(true);

  const { id } = useParams();
  const [params, setParams] = useState({
    history: [],
    data: {
      // model: "gpt-4-1106-preview",
      model: "gpt-3.5-turbo",
      temperature: 0,
      max_tokens: 1024,
      stop: "",
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      json: {
        user: {
          userId: "123456",
          userName: "John Doe",
          subscriptionPlan: "Premium",
          featuresEnabled: [
            {
              name: "Analytics Pro",
              usageHours: 120,
              lastUsed: "2023-10-01",
            },
          ],
          additionalDetails: {
            location: "New York",
            teamSize: "10-50",
            industry: "marketing",
          },
        },
        availableFeatures: [
          {
            name: "Analytics Pro",
            description: "Helps teams understand the usage and performance",
          },
          {
            name: "Team Planner",
            description: "Collaborative space to explore and share ideas",
          },
        ],
      },
      systemTemplate: `You are an expert account manager. You will be provided with a list of available features in a product and then information about a user. You will then recommend the best features to the user.

Features available to the user:

{{#each availableFeatures}}
Feature: {{this.name}}
Description: {{this.description}}

{{/each}}
`,

      userTemplate: `Here is the information about the user:
The user's name is {{user.userName}} and their subscription plan is {{user.subscriptionPlan}}. 

They have been using the following features:
{{#each user.featuresEnabled}}
- Feature: {{this.name}}, Usage Hours: {{this.usageHours}}, Last Used: {{this.lastUsed}}
{{/each}}`,
      systemPreview: `
      You are an expert account manager. You will be provided with a list of available features in a product and then information about a user. You will then recommend the best features to the user.

Features available to the user:

Feature: Analytics Pro
Description: Helps teams understand the usage and performance

Feature: Team Planner
Description: Collaborative space to explore and share ideas`,
      userPreview: `Here is the information about the user:
The user's name is John Doe and their subscription plan is Premium. 

They have been using the following features:
- Feature: Analytics Pro, Usage Hours: 120, Last Used: 2023-10-01`,
      result: "",
    },

    index: 0,
  });

  useEffect(() => {
    auth.onAuthStateChanged(
      function (user) {
        if (user) {
          user.getIdToken().then(function (accessToken) {
            setLoginStatus("logged in");
          });
        } else {
          setLoginStatus("logged out");
        }
      },
      function (error) {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    const fetchParams = async () => {
      setLoadingParams(true);
      if (id) {
        const docRef = doc(db, "paramsCollection", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setParams(docSnap.data());
        } else {
        }
      } else {
      }
      setLoadingParams(false);
    };

    fetchParams();
  }, [id]);

  if (loginStatus === "loading" || loadingParams) {
    return (
      <h1 style={{ textAlign: "center", marginTop: "100px" }}>Loading...</h1>
    );
  }

  if (loginStatus === "logged in" || params.canBeViewedLoggedOut) {
    return (
      <>
        <Header params={params} loggedIn={loginStatus === "logged in"} />
        <Import params={params} setParams={setParams} />
        <Editor params={params} setParams={setParams} />
        <Result params={params} setParams={setParams} />
      </>
    );
  } else if (loginStatus === "logged out") {
    return <Login />;
  } else {
    return <h1 style={{ textAlign: "center", marginTop: "100px" }}>Error</h1>;
  }
}

export default App;
