import "../styles/Login.scss";
import { auth } from "./Firebase";
import StyledFirebaseAuth from "./StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

function Login() {
  const uiConfig = {
    signInFlow: "popup",
    signInSuccessUrl: window.location.pathname.slice(1) || "/",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
  };

  return (
    <div className="LoginWrapper">
      <p>ProductPrompt.ai</p>
      <div id="firebaseui-auth-container"></div>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
    </div>
  );
}

export default Login;
